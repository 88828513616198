import React from 'react';
import { links } from '../../store';
import Whatsapp from '../Socials/Whatsapp';
import './index.scss';

const PriceLink = () => {
  return (
    <a href={links.whatsappToDances} target="_blank" rel="noreferrer" className="price-link">
      <Whatsapp />
      <svg
        className="price-link-svg"
        width="289"
        height="288"
        viewBox="0 0 289 288"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_609_1350)">
          <path
            d="M207.972 143.773C207.972 178.844 179.542 207.273 144.472 207.273C109.402 207.273 80.9722 178.844 80.9722 143.773C80.9722 108.703 109.402 80.2734 144.472 80.2734C179.542 80.2734 207.972 108.703 207.972 143.773Z"
            fill="white"
          />
        </g>
        <path
          d="M239.754 203.156C206.964 255.606 137.864 271.544 85.4138 238.754C32.964 205.964 17.0263 136.864 49.8161 84.4139C82.6058 31.9641 151.706 16.0264 204.156 48.8162C256.606 81.6059 272.543 150.706 239.754 203.156Z"
          stroke="white"
          strokeWidth="5"
        />
        <path
          d="M85.1686 31.4343C86.3283 33.1788 86.6031 35.0609 85.9931 37.0807C85.3831 39.1005 84.0633 40.7868 82.0336 42.1398C79.5811 43.7745 77.2899 44.4952 75.1599 44.3019C73.0356 44.0803 71.2838 42.9505 69.9045 40.9124L73.837 38.2911C74.6364 39.4203 75.5771 40.0276 76.659 40.113C77.7409 40.1984 78.8569 39.8577 80.007 39.0911C80.9204 38.4823 81.4844 37.7152 81.6992 36.7899C81.9197 35.8364 81.754 34.9447 81.2024 34.1148C80.0765 32.4211 78.3803 32.3296 76.1139 33.8404L74.7439 34.7536L72.9537 32.0606C73.168 31.9422 73.5288 31.714 74.0362 31.3757C76.2181 29.9213 76.7629 28.3727 75.6708 26.7298C75.2092 26.0354 74.6093 25.6654 73.8711 25.6197C73.1217 25.5571 72.3326 25.802 71.5038 26.3545C70.5228 27.0084 69.894 27.7698 69.6172 28.6386C69.3292 29.4905 69.4385 30.3709 69.9451 31.2798L66.1902 33.7827C64.9518 31.8462 64.6798 29.95 65.3742 28.0939C66.0687 26.2378 67.4392 24.6277 69.4857 23.2635C71.3462 22.0233 73.1865 21.4688 75.0066 21.5998C76.8324 21.7027 78.2406 22.4993 79.2314 23.9898C79.8281 24.8874 80.098 25.8441 80.0409 26.8598C79.9726 27.8586 79.5944 28.7217 78.9064 29.4492C80.0057 28.9364 81.1356 28.8554 82.2962 29.2061C83.4568 29.5568 84.4143 30.2995 85.1686 31.4343Z"
          fill="white"
        />
        <path
          d="M92.2819 11.3594L108.665 27.8749L104.099 29.9105L100.569 26.1725L93.1907 29.4619L93.5467 34.6149L89.0918 36.6009L87.7992 13.3578L92.2819 11.3594ZM98.0979 23.4657L92.1959 17.244L92.8356 25.8117L98.0979 23.4657Z"
          fill="white"
        />
        <path
          d="M122.832 2.03969L127.955 23.1553L123.335 24.2793L119.168 7.10804L110.55 9.20475L114.716 26.376L110.096 27.5L104.973 6.38442L122.832 2.03969Z"
          fill="white"
        />
        <path
          d="M148.317 0L149.489 21.6981L144.741 21.9552L143.928 6.90063L143.837 6.90557L136.494 22.4019L131.138 22.6919L129.966 0.993826L134.714 0.736717L135.525 15.7608L135.616 15.7559L142.961 0.290072L148.317 0Z"
          fill="white"
        />
        <path
          d="M172.943 15.9806C172.353 18.6536 171.13 20.6807 169.272 22.0619C167.415 23.4432 165.046 23.9481 162.164 23.5767C159.04 23.1742 156.653 21.8204 155.004 19.5153C153.376 17.1926 152.782 14.3261 153.22 10.9157C153.635 7.68696 154.937 5.1264 157.127 3.23406C159.336 1.34432 162.043 0.605912 165.247 1.01885C167.807 1.34868 169.859 2.35174 171.406 4.02804C172.952 5.70433 173.672 7.67415 173.566 9.93748L168.941 9.34145C168.852 8.11968 168.426 7.13138 167.662 6.37654C166.922 5.60413 165.917 5.13611 164.647 4.9725C163.337 4.80369 162.181 5.03418 161.178 5.66396C160.198 6.27616 159.458 7.09366 158.958 8.11644C158.457 9.13923 158.126 10.2863 157.962 11.5576C157.677 13.7774 157.963 15.6194 158.819 17.0838C159.678 18.5279 160.974 19.3617 162.708 19.585C165.589 19.9564 167.439 18.5537 168.257 15.3768L172.943 15.9806Z"
          fill="white"
        />
        <path
          d="M190.836 7.44627L192.154 30.688L187.389 29.1777L187.224 24.0356L179.524 21.5953L176.376 25.6877L171.727 24.2143L186.158 5.96367L190.836 7.44627ZM187.168 20.3687L186.873 11.7923L181.676 18.6282L187.168 20.3687Z"
          fill="white"
        />
        <path
          d="M217.693 19.5004L215.848 23.1147L210.041 20.1422L202.009 35.8766L197.749 33.6958L205.78 17.9614L199.973 14.9889L201.818 11.3746L217.693 19.5004Z"
          fill="white"
        />
        <path
          d="M227.95 44.8617C226.578 46.7303 224.961 47.7121 223.101 47.807C221.269 47.8976 219.371 47.2196 217.407 45.7732L209.672 40.0779L222.528 22.5717L226.334 25.3742L221.711 31.6685L225.836 34.706C227.686 36.0681 228.835 37.6463 229.282 39.4408C229.742 41.2189 229.298 43.0258 227.95 44.8617ZM224.254 42.0643C225.482 40.3924 225.097 38.8211 223.1 37.3506L219.491 34.6928L215.789 39.7332L219.423 42.4091C221.404 43.8675 223.014 43.7526 224.254 42.0643Z"
          fill="white"
        />
        <path
          d="M244.636 60.0778C242.496 61.7809 240.271 62.5825 237.959 62.4825C235.648 62.3825 233.491 61.2789 231.487 59.1717C229.315 56.8877 228.315 54.3302 228.485 51.4993C228.683 48.6691 230.026 46.0685 232.513 43.6975C234.868 41.4528 237.49 40.2868 240.381 40.1995C243.286 40.127 245.852 41.2623 248.08 43.6052C249.859 45.4767 250.821 47.5511 250.967 49.8285C251.112 52.1059 250.431 54.0896 248.924 55.7795L245.708 52.3976C246.409 51.393 246.7 50.3568 246.582 49.289C246.493 48.2218 246.008 47.2241 245.125 46.2957C244.214 45.3379 243.172 44.787 241.997 44.643C240.851 44.4997 239.761 44.6672 238.729 45.1455C237.697 45.6238 236.718 46.3049 235.79 47.1888C234.172 48.732 233.234 50.3424 232.977 52.0199C232.735 53.6833 233.217 55.1487 234.422 56.416C236.425 58.5232 238.744 58.6018 241.379 56.6517L244.636 60.0778Z"
          fill="white"
        />
        <path
          d="M252.816 72.0153L242.232 71.3268L238.979 66.3094L250.009 67.1811C249.514 65.7078 249.461 64.2991 249.849 62.955C250.266 61.617 251.173 60.4938 252.57 59.5854C254.411 58.389 256.181 58.0749 257.882 58.6432C259.594 59.2285 261.036 60.4257 262.209 62.2347L268 71.1688L249.799 83L247.226 79.0321L254.359 74.396L252.816 72.0153ZM257.247 72.5183L262.284 69.2448L259.711 65.277C259.048 64.253 258.346 63.5446 257.607 63.1518C256.879 62.776 256.038 62.8983 255.084 63.5187C254.061 64.1834 253.57 64.9392 253.61 65.7862C253.679 66.6392 254.072 67.6204 254.792 68.7297L257.247 72.5183Z"
          fill="white"
        />
        <path
          d="M204.831 255.566C203.672 253.821 203.397 251.939 204.007 249.919C204.617 247.9 205.937 246.213 207.966 244.86C210.419 243.225 212.71 242.505 214.84 242.698C216.964 242.92 218.716 244.049 220.095 246.088L216.163 248.709C215.364 247.58 214.423 246.972 213.341 246.887C212.259 246.802 211.143 247.142 209.993 247.909C209.08 248.518 208.516 249.285 208.301 250.21C208.08 251.164 208.246 252.055 208.798 252.885C209.924 254.579 211.62 254.67 213.886 253.16L215.256 252.246L217.046 254.939C216.832 255.058 216.471 255.286 215.964 255.624C213.782 257.079 213.237 258.627 214.329 260.27C214.791 260.965 215.391 261.335 216.129 261.38C216.878 261.443 217.667 261.198 218.496 260.646C219.477 259.992 220.106 259.23 220.383 258.361C220.671 257.51 220.561 256.629 220.055 255.72L223.81 253.217C225.048 255.154 225.32 257.05 224.626 258.906C223.931 260.762 222.561 262.372 220.514 263.737C218.654 264.977 216.813 265.531 214.993 265.4C213.168 265.297 211.759 264.501 210.769 263.01C210.172 262.113 209.902 261.156 209.959 260.14C210.027 259.141 210.406 258.278 211.094 257.551C209.994 258.064 208.864 258.145 207.704 257.794C206.543 257.443 205.586 256.701 204.831 255.566Z"
          fill="white"
        />
        <path
          d="M197.718 275.641L181.335 259.125L185.901 257.089L189.431 260.827L196.809 257.538L196.453 252.385L200.908 250.399L202.201 273.642L197.718 275.641ZM191.902 263.534L197.804 269.756L197.164 261.188L191.902 263.534Z"
          fill="white"
        />
        <path
          d="M167.168 284.96L162.045 263.845L166.665 262.721L170.832 279.892L179.45 277.795L175.284 260.624L179.904 259.5L185.027 280.616L167.168 284.96Z"
          fill="white"
        />
        <path
          d="M141.683 287L140.511 265.302L145.259 265.045L146.072 280.099L146.163 280.094L153.506 264.598L158.862 264.308L160.034 286.006L155.286 286.263L154.475 271.239L154.384 271.244L147.039 286.71L141.683 287Z"
          fill="white"
        />
        <path
          d="M117.057 271.019C117.647 268.346 118.87 266.319 120.728 264.938C122.585 263.557 124.954 263.052 127.836 263.423C130.96 263.826 133.347 265.18 134.996 267.485C136.624 269.807 137.218 272.674 136.78 276.084C136.365 279.313 135.063 281.874 132.873 283.766C130.664 285.656 127.957 286.394 124.753 285.981C122.193 285.651 120.141 284.648 118.594 282.972C117.048 281.296 116.328 279.326 116.434 277.063L121.059 277.659C121.148 278.88 121.574 279.869 122.338 280.623C123.078 281.396 124.083 281.864 125.353 282.028C126.663 282.196 127.819 281.966 128.822 281.336C129.802 280.724 130.542 279.906 131.042 278.884C131.543 277.861 131.874 276.714 132.038 275.442C132.323 273.223 132.037 271.381 131.181 269.916C130.322 268.472 129.026 267.638 127.292 267.415C124.411 267.044 122.561 268.446 121.743 271.623L117.057 271.019Z"
          fill="white"
        />
        <path
          d="M99.1637 279.554L97.846 256.312L102.611 257.822L102.776 262.964L110.476 265.405L113.624 261.312L118.273 262.786L103.842 281.036L99.1637 279.554ZM102.832 266.631L103.127 275.208L108.324 268.372L102.832 266.631Z"
          fill="white"
        />
        <path
          d="M72.3068 267.5L74.1517 263.885L79.9591 266.858L87.9906 251.123L92.2513 253.304L84.2198 269.039L90.0273 272.011L88.1824 275.625L72.3068 267.5Z"
          fill="white"
        />
        <path
          d="M62.0503 242.138C63.4225 240.27 65.0387 239.288 66.8991 239.193C68.731 239.102 70.6291 239.78 72.5934 241.227L80.3277 246.922L67.4722 264.428L63.6665 261.626L68.2887 255.331L64.1637 252.294C62.314 250.932 61.1653 249.354 60.7176 247.559C60.2579 245.781 60.7021 243.974 62.0503 242.138ZM65.7463 244.936C64.5185 246.608 64.9031 248.179 66.9001 249.649L70.5095 252.307L74.2108 247.267L70.5769 244.591C68.5963 243.132 66.9861 243.247 65.7463 244.936Z"
          fill="white"
        />
        <path
          d="M45.3637 226.922C47.5037 225.219 49.7293 224.417 52.0407 224.517C54.3521 224.617 56.5095 225.721 58.513 227.828C60.6846 230.112 61.6853 232.67 61.5153 235.501C61.3166 238.331 59.9738 240.932 57.4869 243.303C55.1324 245.547 52.5098 246.713 49.619 246.8C46.7141 246.873 44.1479 245.738 41.9203 243.395C40.141 241.523 39.1786 239.449 39.0333 237.172C38.8879 234.894 39.5689 232.91 41.0762 231.221L44.2915 234.602C43.5915 235.607 43.3002 236.643 43.4177 237.711C43.5065 238.778 43.9923 239.776 44.8749 240.704C45.7856 241.662 46.8284 242.213 48.0033 242.357C49.1495 242.5 50.2387 242.333 51.2708 241.854C52.3029 241.376 53.2825 240.695 54.2095 239.811C55.8282 238.268 56.7659 236.658 57.0226 234.98C57.2646 233.317 56.7832 231.851 55.5783 230.584C53.5748 228.477 51.2557 228.398 48.6211 230.348L45.3637 226.922Z"
          fill="white"
        />
        <path
          d="M37.1845 214.985L47.7684 215.673L51.0208 220.691L39.9911 219.819C40.4859 221.292 40.5392 222.701 40.1508 224.045C39.7343 225.383 38.8273 226.506 37.4298 227.415C35.5892 228.611 33.8185 228.925 32.1179 228.357C30.4062 227.771 28.964 226.574 27.7913 224.765L22 215.831L40.2015 204L42.7736 207.968L35.6413 212.604L37.1845 214.985ZM32.7525 214.482L27.7164 217.755L30.2885 221.723C30.9523 222.747 31.6537 223.455 32.3928 223.848C33.1209 224.224 33.9621 224.102 34.9165 223.481C35.939 222.817 36.4301 222.061 36.3897 221.214C36.3213 220.361 35.9275 219.38 35.2084 218.27L32.7525 214.482Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_609_1350"
            x="0.972168"
            y="0.273438"
            width="287"
            height="287"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="40" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_609_1350" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_609_1350"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      {/* <svg
        className="price-link-svg"
        width="424"
        height="422"
        viewBox="0 0 424 422"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M347.232 229.172L329.49 227.731L329.805 223.844L344.233 225.015L344.822 217.764L330.394 216.593L330.71 212.706L348.451 214.146L347.232 229.172Z"
          fill="white"
        />
        <path
          d="M346.03 236.366L344.22 243.825C343.749 245.768 342.906 247.193 341.691 248.099C340.489 249.025 338.964 249.264 337.118 248.816C335.304 248.376 334.065 247.475 333.401 246.113C332.752 244.755 332.65 243.161 333.095 241.331L334.038 237.444L327.818 235.935L328.732 232.169L346.03 236.366ZM342.007 239.377L337.026 238.169L336.207 241.546C335.727 243.522 336.313 244.71 337.966 245.111C339.634 245.516 340.706 244.738 341.181 242.779L342.007 239.377Z"
          fill="white"
        />
        <path
          d="M337.39 264.2L318.33 263.639L319.899 259.851L324.107 260.078L326.642 253.958L323.524 251.097L325.055 247.401L338.931 260.481L337.39 264.2ZM327.103 260.291L334.123 260.655L328.911 255.926L327.103 260.291Z"
          fill="white"
        />
        <path
          d="M324.238 288.291L309.082 278.957L311.127 275.637L321.643 282.113L321.682 282.049L314.679 269.868L316.986 266.121L332.143 275.455L330.098 278.776L319.603 272.313L319.564 272.377L326.545 284.545L324.238 288.291ZM330.868 286.678L330.134 287.871C328.091 286.612 327.781 284.826 329.206 282.513C330.63 280.2 332.364 279.672 334.408 280.931L333.674 282.123C332.624 281.731 331.688 282.201 330.866 283.535C330.045 284.869 330.045 285.917 330.868 286.678Z"
          fill="white"
        />
        <path
          d="M300.988 299.235C299.441 297.612 298.623 295.852 298.534 293.957C298.445 292.061 299.187 290.217 300.758 288.426C302.461 286.483 304.474 285.477 306.796 285.408C309.12 285.362 311.341 286.268 313.458 288.125C315.463 289.883 316.608 291.94 316.892 294.295C317.165 296.663 316.428 298.843 314.681 300.836C313.285 302.427 311.663 303.366 309.816 303.652C307.969 303.938 306.3 303.528 304.811 302.421L307.333 299.545C308.204 300.043 309.071 300.205 309.933 300.03C310.797 299.879 311.576 299.409 312.268 298.62C312.982 297.805 313.355 296.913 313.386 295.942C313.418 294.995 313.201 294.118 312.735 293.31C312.269 292.502 311.641 291.752 310.851 291.059C309.473 289.851 308.09 289.203 306.703 289.117C305.328 289.041 304.168 289.543 303.223 290.62C301.651 292.412 301.758 294.312 303.543 296.321L300.988 299.235Z"
          fill="white"
        />
        <path
          d="M115.877 113.823L129.074 125.768L126.457 128.659L115.726 118.946L110.844 124.339L121.575 134.053L118.958 136.944L105.761 125L115.877 113.823Z"
          fill="white"
        />
        <path
          d="M121.211 108.847L127.188 104.033C128.746 102.778 130.282 102.161 131.797 102.182C133.314 102.18 134.669 102.918 135.861 104.398C137.032 105.851 137.466 107.321 137.164 108.805C136.852 110.277 135.962 111.604 134.495 112.785L131.38 115.294L135.395 120.278L132.377 122.709L121.211 108.847ZM126.236 108.908L129.451 112.9L132.157 110.72C133.741 109.444 133.999 108.144 132.933 106.821C131.856 105.484 130.532 105.448 128.962 106.713L126.236 108.908Z"
          fill="white"
        />
        <path
          d="M145.012 92.0279L159.79 104.079L156.239 106.129L153.04 103.386L147.302 106.698L148.033 110.865L144.569 112.865L141.526 94.04L145.012 92.0279ZM150.792 101.392L145.445 96.8293L146.7 103.754L150.792 101.392Z"
          fill="white"
        />
        <path
          d="M170.114 80.9254L176.454 97.558L172.81 98.9471L168.411 87.4071L168.341 87.4338L166.479 101.36L162.368 102.928L156.027 86.2949L159.672 84.9058L164.062 96.4225L164.132 96.3958L166.002 82.4926L170.114 80.9254ZM163.872 78.1677L165.18 77.6691C166.035 79.9117 165.193 81.5168 162.655 82.4844C160.116 83.4521 158.42 82.8146 157.565 80.572L158.873 80.0733C159.467 81.024 160.496 81.2203 161.96 80.6623C163.424 80.1043 164.061 79.2728 163.872 78.1677Z"
          fill="white"
        />
        <path
          d="M195.221 86.4008C195.46 88.6303 195.037 90.524 193.954 92.0818C192.87 93.6396 191.159 94.6507 188.821 95.1153C186.288 95.6188 184.079 95.1912 182.194 93.8325C180.323 92.4541 179.113 90.3836 178.564 87.6209C178.044 85.0054 178.389 82.677 179.597 80.6356C180.822 78.5909 182.734 77.3103 185.333 76.7938C187.409 76.3812 189.267 76.6237 190.907 77.5213C192.547 78.4189 193.621 79.7603 194.128 81.5456L190.377 82.2912C189.989 81.3657 189.4 80.7096 188.609 80.323C187.832 79.9167 186.928 79.8159 185.898 80.0206C184.836 80.2317 183.997 80.7128 183.381 81.4639C182.779 82.1953 182.417 83.0235 182.295 83.9483C182.173 84.8732 182.214 85.8505 182.419 86.8804C182.776 88.6786 183.479 90.0343 184.527 90.9475C185.572 91.8444 186.797 92.1531 188.203 91.8737C190.54 91.4092 191.613 89.8366 191.42 87.1562L195.221 86.4008Z"
          fill="white"
        />
        <path
          d="M145.654 330.086L153.446 314.082L156.953 315.789L150.616 328.804L157.157 331.988L163.493 318.974L167 320.682L159.207 336.685L145.654 330.086Z"
          fill="white"
        />
        <path
          d="M139.389 326.351L133.098 321.953C131.459 320.807 130.439 319.503 130.036 318.043C129.611 316.586 129.943 315.079 131.031 313.522C132.101 311.992 133.391 311.165 134.901 311.042C136.401 310.932 137.923 311.416 139.467 312.495L142.745 314.787L146.412 309.542L149.588 311.763L139.389 326.351ZM138.046 321.508L140.983 317.308L138.135 315.316C136.469 314.151 135.148 314.265 134.174 315.659C133.191 317.065 133.525 318.347 135.178 319.502L138.046 321.508Z"
          fill="white"
        />
        <path
          d="M116.601 308.182L124.054 290.63L127.013 293.469L125.271 297.306L130.051 301.893L133.849 300.029L136.735 302.799L119.505 310.969L116.601 308.182ZM123.983 300.02L121.091 306.428L127.392 303.292L123.983 300.02Z"
          fill="white"
        />
        <path
          d="M98.9408 287.171L113.147 276.445L115.497 279.558L105.64 286.999L105.685 287.059L119.579 284.965L122.23 288.476L108.024 299.202L105.674 296.089L115.511 288.663L115.465 288.603L101.592 290.682L98.9408 287.171ZM98.0324 293.934L97.1888 292.816C99.1042 291.37 100.88 291.731 102.517 293.899C104.154 296.067 104.015 297.874 102.1 299.321L101.256 298.203C102.003 297.368 101.905 296.325 100.961 295.074C100.017 293.824 99.0409 293.444 98.0324 293.934Z"
          fill="white"
        />
        <path
          d="M97.2 261.533C99.2744 260.681 101.211 260.559 103.009 261.166C104.807 261.772 106.255 263.133 107.353 265.249C108.543 267.542 108.748 269.782 107.968 271.971C107.166 274.152 105.515 275.891 103.015 277.189C100.648 278.417 98.3161 278.736 96.0187 278.144C93.7136 277.538 91.9507 276.058 90.7301 273.706C89.7551 271.827 89.4701 269.976 89.875 268.151C90.2799 266.326 91.2688 264.92 92.8418 263.935L94.6036 267.33C93.823 267.961 93.3572 268.709 93.2062 269.576C93.0328 270.436 93.1879 271.332 93.6715 272.264C94.1705 273.226 94.8665 273.897 95.7593 274.279C96.6296 274.654 97.5259 274.771 98.4481 274.63C99.3704 274.489 100.297 274.177 101.229 273.694C102.857 272.849 103.963 271.796 104.548 270.535C105.118 269.282 105.073 268.019 104.412 266.747C103.315 264.631 101.505 264.04 98.9849 264.972L97.2 261.533Z"
          fill="white"
        />
        <path
          d="M323.472 210.773C323.472 272.629 273.328 322.773 211.472 322.773C149.616 322.773 99.4722 272.629 99.4722 210.773C99.4722 148.918 149.616 98.7734 211.472 98.7734C273.328 98.7734 323.472 148.918 323.472 210.773Z"
          stroke="white"
          strokeWidth="5"
        />
        <g filter="url(#filter0_d_291_1599)">
          <path
            d="M274.972 210.773C274.972 245.844 246.542 274.273 211.472 274.273C176.402 274.273 147.972 245.844 147.972 210.773C147.972 175.703 176.402 147.273 211.472 147.273C246.542 147.273 274.972 175.703 274.972 210.773Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_291_1599"
            x="67.9722"
            y="67.2734"
            width="287"
            height="287"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="40" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_291_1599" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_291_1599"
              result="shape"
            />
          </filter>
        </defs>
      </svg> */}
    </a>
  );
};

export default PriceLink;
