import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { links } from '../../store';
import './index.scss';

const checkDeadline = () => {
  if (localStorage.getItem('offerTimer')) {
    return moment(localStorage.getItem('offerTimer'));
  } else {
    localStorage.setItem('offerTimer', moment().add(1, 'day'));
    return moment().add(1, 'day');
  }
};

const SpecialOffer = ({ titleRef }) => {
  const [top, setTop] = useState(undefined);
  const [hidden, setHidden] = useState(true);

  const [timer, setTimer] = useState({
    h: '00',
    m: '00',
    s: '00',
  });

  useEffect(() => {
    // Mobile View - Position
    const mobileViewResize = () => {
      if (window.innerWidth <= 800) {
        const { height } = titleRef.current.getBoundingClientRect();
        setTop(height + 100 + 15)
      } else {
        setTop(undefined)
      }
    }
    setTimeout(() => {
      mobileViewResize()
    }, 200);
    

    setTimeout(() => {
      setHidden(false);
    }, 1000);

    const timerInterval = setInterval(() => {
      const deadline = checkDeadline();
      const duration = moment.duration(deadline.diff(moment()));

      if (duration < 0) {
        localStorage.setItem('offerTimer', moment().add(1, 'day'));
      }

      setTimer({
        h: duration.hours(),
        m: duration.minutes(),
        s: duration.seconds(),
      });
    }, 1000);

    window.addEventListener('resize', mobileViewResize)
    
    return () => {
      window.removeEventListener('resize', mobileViewResize)
      clearInterval(timerInterval)
    };
  }, []);

  return (
    <div className={`special-offer ${hidden ? 'hide' : ''}`} style={{ top }}>
      <div className="special-offer-stars">
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </div>

      <div className="special-offer-top">
        <h4>Ограниченое предложение</h4>
        <button onClick={() => setHidden(true)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.121 12.0001L18 8.11706C18.2519 7.83082 18.3853 7.45942 18.3731 7.07836C18.3609 6.69729 18.2041 6.33516 17.9345 6.06556C17.6649 5.79597 17.3028 5.63915 16.9217 5.62699C16.5407 5.61482 16.1693 5.74822 15.883 6.00006L12 9.87907L8.11002 5.98806C7.97068 5.84873 7.80527 5.73821 7.62323 5.6628C7.44118 5.5874 7.24606 5.54858 7.04902 5.54858C6.85197 5.54858 6.65685 5.5874 6.47481 5.6628C6.29276 5.73821 6.12735 5.84873 5.98802 5.98806C5.84868 6.1274 5.73816 6.29281 5.66275 6.47486C5.58735 6.6569 5.54854 6.85202 5.54854 7.04906C5.54854 7.24611 5.58735 7.44123 5.66275 7.62327C5.73816 7.80532 5.84868 7.97073 5.98802 8.11006L9.87902 12.0001L6.00002 15.8821C5.84789 16.0181 5.72511 16.1837 5.63918 16.3687C5.55325 16.5538 5.50599 16.7545 5.50028 16.9584C5.49457 17.1624 5.53054 17.3654 5.60598 17.555C5.68142 17.7446 5.79475 17.9168 5.93903 18.061C6.08332 18.2053 6.25552 18.3187 6.44511 18.3941C6.6347 18.4695 6.83769 18.5055 7.04166 18.4998C7.24563 18.4941 7.44628 18.4468 7.63136 18.3609C7.81643 18.275 7.98203 18.1522 8.11802 18.0001L12 14.1211L15.878 18.0001C16.1594 18.2815 16.5411 18.4395 16.939 18.4395C17.337 18.4395 17.7186 18.2815 18 18.0001C18.2814 17.7187 18.4395 17.337 18.4395 16.9391C18.4395 16.5411 18.2814 16.1595 18 15.8781L14.121 12.0001Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="special-offer-body">
        <p>
          Запишись на <b>БЕСПЛАТНОЕ ПРОБНОЕ</b> прямо сейчас
        </p>
        <div className="special-offer-timer">
          <div className="special-offer-timer-value">{timer.h.toString().padStart(2, '0')}</div>
          <div className="special-offer-timer-separator">:</div>
          <div className="special-offer-timer-value">{timer.m.toString().padStart(2, '0')}</div>
          <div className="special-offer-timer-separator">:</div>
          <div className="special-offer-timer-value">{timer.s.toString().padStart(2, '0')}</div>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={links.whatsappOffer}
          className="special-offer-link">
          Записаться
        </a>
      </div>
    </div>
  );
};

const Star = () => (
  <div className="special-offer-stars__item">
    {' '}
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 0L22.8616 13.1384L36 18L22.8616 22.8616L18 36L13.1384 22.8616L0 18L13.1384 13.1384L18 0Z"
        fill="#403F41"
      />
    </svg>
  </div>
);

export default SpecialOffer;
