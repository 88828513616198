import 'moment/locale/ru';
import { useEffect, useState } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Shedule from './components/Shedule/Shedule';
import AboutSection from './sections/About/About.section';
import MainSection from './sections/Main/Main.section';
import MastersSection from './sections/Masters/Masters.section';
import PlatformSection from './sections/Platform/Platform.section';
import StudySection from './sections/Study/Study.section';
import Form from './sections/Form/Form.section';

function App() {
  const [openShedule, setOpenShedule] = useState(false);

  useEffect(() => {
    const resizeHandle = () => {
      document.querySelector('body').style.setProperty('--vh', window.innerHeight / 100 + 'px');
    };

    resizeHandle()

    window.addEventListener('resize', resizeHandle);
    return () => window.removeEventListener('resize', resizeHandle);
  }, []);

  return (
    <div className="app">
      <Header openShedule={() => setOpenShedule((prev) => !prev)} />
      <Shedule open={openShedule} onClose={() => setOpenShedule(false)} />
      <MainSection id="главная" />
      <div className="app-dark">
        <AboutSection id="о-нас" />
        <StudySection id="система-обучения" />
        <MastersSection id="преподаватели" />
        <PlatformSection />
        <Form />
        <Footer />
      </div>
    </div>
  );
}

export default App;
