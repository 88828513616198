import React from 'react';
import './detail.scss';
import { links } from '../../store';

const MasterDetail = ({ master, open, onClose }) => {
  return (
    <div className={`section-masters-detail${open ? '' : ' hide'}`}>
      <div
        className="section-masters-detail-hero"
        style={{ backgroundImage: `url(${master?.hero})` }}></div>
      <div className="section-masters-detail-main">
        <button onClick={onClose} className="section-masters-detail-close">
          закрыть
        </button>

        <div className="section-masters-detail-title">
          <div className="section-masters-detail-title-hero" style={{ backgroundImage: `url(${master?.hero})` }}></div>
          <h1>{master?.name}</h1>
          <h5>
            <div>Хореограф в направлении</div> {master?.dances}
          </h5>
        </div>

        <div className="section-masters-detail-main-wrap">
          {!!master?.places?.length && (
            <div className="section-masters-detail-places">
              {master.places.map((place, i) => (
                <div key={i} className="section-masters-detail-places__item">
                  {!!place.length && (
                    <h1>
                      <span>{i + 1}</span>
                      <span>{i === 2 ? '+' : ''}</span>
                      <span>место</span>
                    </h1>
                  )}
                  <ul>
                    {place.map((x) => (
                      <li key={x} className="section-masters-detail-places__event">
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          <div className="section-masters-detail-description">
            <h4>Достижения</h4>
            <div className="section-masters-detail-description-text">{master?.description}</div>
          </div>
        </div>

        <a href={links.whatsappToMaster(master?.name)} target='_blank' rel='noreferrer' className="section-masters-detail-book">Записаться</a>
      </div>
    </div>
  );
};

export default MasterDetail;
