import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { masters } from '../../store';
import MasterDetail from './MasterDetail';

const rows = ['1 / 3', '1 / 2', '2 / 3'];

const MAX_VOLUME = 0.6;

const MastersSection = ({ id }) => {
  const scrollbarWidth = useRef();

  useEffect(() => {
    scrollbarWidth.current = getScrollbarWidth()

    document.querySelectorAll(`[data-master]`).forEach((item) => {
      const slug = item.getAttribute('data-master');
      item.children[0].currentTime = masters.filter((x) => x.slug === slug)[0].startTime || 0;
    });
  }, []);

  const toggleAudio = (slug) => {
    const target = document.querySelector(`[data-master="${slug}"]`);
    if (!target || target.classList.contains('smoothing')) return;
    const video = target.children[0];

    video.muted = !video.muted;
    if (video.muted) target.classList.add('muted');
    else target.classList.remove('muted');
  };

  const endVideo = (slug) => {
    const target = document.querySelector(`[data-master="${slug}"]`);
    if (!target || target.classList.contains('smoothing')) return;
    const video = target.children[0];
    target.classList.remove('playing');

    // Smooth volume
    target.classList.add('smoothing');
    const interval = setInterval(() => {
      if (video.volume <= 0.1) {
        video.volume = 0;
        target.classList.remove('smoothing');
        video.pause();
        video.currentTime = masters.filter((x) => x.slug === slug)[0]?.startTime || 0;
        clearInterval(interval);
      } else {
        video.volume -= 0.1;
        console.log('-');
      }
    }, 100);
  };

  const playVideo = (slug) => {
    const target = document.querySelector(`[data-master="${slug}"]`);
    if (!target || target.classList.contains('smoothing')) return;
    const video = target.children[0];
    target.classList.add('playing');

    // Events
    video.onended = () => {
      endVideo(slug);
    };

    video.muted = false;
    target.classList.remove('muted');

    // Smooth volume
    video.volume = 0;
    target.classList.add('smoothing');
    const interval = setInterval(() => {
      if (video.volume >= MAX_VOLUME) {
        video.volume = MAX_VOLUME;
        target.classList.remove('smoothing');
        clearInterval(interval);
      } else {
        video.volume += 0.1;
      }
    }, 100);

    video.currentTime = masters.filter((x) => x.slug === slug)[0]?.startTime || 0;
    video.play();
  };

  const [offset, setOffset] = useState(0);
  const [navVisible, setNavVisible] = useState({
    left: false,
    right: true,
  });

  const NAV_STEP = window.innerWidth * 0.6;

  const toLeftNav = () => {
    if (offset + NAV_STEP > 0) {
      setOffset(0);
      setNavVisible({ left: false, right: true });
    } else {
      setOffset((prev) => prev + NAV_STEP);
      setNavVisible({ left: true, right: true });
    }
  };

  const toRightNav = () => {
    const wrap = document.querySelector('.section-masters-wrap').scrollWidth;
    const screen = window.innerWidth;

    if (wrap + offset - NAV_STEP < screen) {
      setOffset(-wrap + screen - scrollbarWidth.current);
      setNavVisible({ left: true, right: false });
    } else {
      setNavVisible({ left: true, right: true });
      setOffset((prev) => prev - NAV_STEP);
    }
  };

  const [activeDetail, setActiveDetail] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);

  const activateDetail = (slug) => {
    setOpenDetail(true)
    setActiveDetail(masters.filter(x => x.slug === slug)[0])
  }

  const deactivateDetail = () => {
    setOpenDetail(false)
  }

  return (
    <section id={id} className="section section-masters">

      <div className="section-masters-nav">
        <NavItem hide={!navVisible.left} onClick={toLeftNav} />
        <NavItem hide={!navVisible.right} onClick={toRightNav} />
      </div>

      <div onClick={deactivateDetail} className={`section-masters-overlay${!openDetail ? ' hide' : ''}`}></div>

      <MasterDetail master={activeDetail} open={openDetail} onClose={deactivateDetail} />

      <div className={`section-masters-wrap${activeDetail ? ' hide' : ''}`} style={{ transform: `translateX(${offset}px)` }}>
        {masters.map((master, i) => (
          <div
            data-master={master.slug}
            style={{ gridRow: rows[i % rows.length] }}
            className={`section-masters__item`}
            key={master.slug}>
            <video width="100%" height="100%">
              <source src={master.video} type="video/mp4" />
            </video>
            <div className="section-masters__item-tools">
              <button onClick={() => toggleAudio(master.slug)}>
                <VolumeSVG />
                <MutedSVG />
              </button>
              <button onClick={() => endVideo(master.slug)}>
                <PauseSVG />
              </button>
            </div>
            <div className="section-masters__item-overlay"></div>
            <div className="section-masters__item-wrap">
              <h1 className="box-title">
                <span>{master.name}</span>
              </h1>
              <h4 className="box-title">
                <span><div>Хореограф в направлении</div>{master.dances}</span>
              </h4>
              <div className="section-masters__item-btns">
                <button onClick={() => activateDetail(master.slug)}>Подробнее</button>
                <button onClick={() => playVideo(master.slug)}>
                  <PlaySVG />
                  Видео
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const PlaySVG = () => (
  <svg
    className="play-svg"
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4321 6.30352L6.10788 0.764915C5.53582 0.332917 4.85893 0.0727175 4.15224 0.0131585C3.44556 -0.0464005 2.73668 0.0970073 2.10419 0.427486C1.4717 0.757964 0.940304 1.2626 0.568898 1.88547C0.197491 2.50834 0.000584487 3.2251 4.63342e-06 3.9563V15.0391C-0.00109845 15.771 0.194778 16.4889 0.565831 17.1128C0.936885 17.7367 1.46856 18.2422 2.10167 18.573C2.73477 18.9038 3.44448 19.047 4.15179 18.9865C4.8591 18.926 5.53625 18.6643 6.10788 18.2305L13.4321 12.6918C13.9181 12.324 14.3133 11.8434 14.5857 11.2888C14.8581 10.7342 15 10.1213 15 9.49966C15 8.878 14.8581 8.26508 14.5857 7.7105C14.3133 7.15592 13.9181 6.67528 13.4321 6.30748V6.30352Z"
      fill="white"
    />
  </svg>
);

const PauseSVG = () => (
  <svg
    className="pause-svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 0C11.1435 0 9.36301 0.737498 8.05025 2.05025C6.7375 3.36301 6 5.14348 6 7V41C6 42.8565 6.7375 44.637 8.05025 45.9497C9.36301 47.2625 11.1435 48 13 48C14.8565 48 16.637 47.2625 17.9497 45.9497C19.2625 44.637 20 42.8565 20 41V7C20 5.14348 19.2625 3.36301 17.9497 2.05025C16.637 0.737498 14.8565 0 13 0Z"
      fill="white"
    />
    <path
      d="M35 0C33.1435 0 31.363 0.737498 30.0503 2.05025C28.7375 3.36301 28 5.14348 28 7V41C28 42.8565 28.7375 44.637 30.0503 45.9497C31.363 47.2625 33.1435 48 35 48C36.8565 48 38.637 47.2625 39.9498 45.9497C41.2625 44.637 42 42.8565 42 41V7C42 5.14348 41.2625 3.36301 39.9498 2.05025C38.637 0.737498 36.8565 0 35 0Z"
      fill="white"
    />
  </svg>
);

const VolumeSVG = () => (
  <svg
    className="volume-svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.79 4.25598C20.5071 3.98274 20.1282 3.83155 19.7349 3.83497C19.3416 3.83839 18.9654 3.99614 18.6873 4.27425C18.4092 4.55237 18.2514 4.92858 18.248 5.32188C18.2446 5.71517 18.3958 6.09408 18.669 6.37698C19.4074 7.1154 19.9932 7.99203 20.3928 8.95683C20.7925 9.92162 20.9982 10.9557 20.9982 12C20.9982 13.0443 20.7925 14.0783 20.3928 15.0431C19.9932 16.0079 19.4074 16.8846 18.669 17.623C18.3958 17.9059 18.2446 18.2848 18.248 18.6781C18.2514 19.0714 18.4092 19.4476 18.6873 19.7257C18.9654 20.0038 19.3416 20.1616 19.7349 20.165C20.1282 20.1684 20.5071 20.0172 20.79 19.744C21.807 18.7271 22.6138 17.5198 23.1642 16.1911C23.7146 14.8623 23.9979 13.4382 23.9979 12C23.9979 10.5618 23.7146 9.13763 23.1642 7.80891C22.6138 6.48018 21.807 5.27289 20.79 4.25598Z"
      fill="white"
    />
    <path
      d="M18.652 12C18.6498 11.0404 18.3307 10.1084 17.7442 9.34887C17.1578 8.58935 16.3368 8.04484 15.409 7.79998V1.67998C15.409 1.46007 15.3606 1.24286 15.2672 1.04373C15.1739 0.844607 15.0379 0.668436 14.869 0.527699C14.7 0.386963 14.5021 0.285102 14.2894 0.229333C14.0767 0.173564 13.8543 0.165251 13.638 0.204981C10.6941 0.756901 8.06116 2.38496 6.252 4.77198H5.5C4.0418 4.77357 2.64377 5.35354 1.61267 6.38465C0.581561 7.41576 0.00158817 8.81378 0 10.272V13.728C0.00158817 15.1862 0.581561 16.5842 1.61267 17.6153C2.64377 18.6464 4.0418 19.2264 5.5 19.228H6.254C8.06181 21.6165 10.6941 23.2463 13.638 23.8C13.7274 23.8167 13.8181 23.8251 13.909 23.825C14.3068 23.825 14.6884 23.6669 14.9697 23.3856C15.251 23.1043 15.409 22.7228 15.409 22.325V16.2C16.3368 15.9551 17.1578 15.4106 17.7442 14.6511C18.3307 13.8916 18.6498 12.9596 18.652 12ZM12.409 20.322C10.7226 19.6236 9.28553 18.4336 8.285 16.907C8.14856 16.6983 7.96226 16.527 7.74297 16.4085C7.52367 16.2899 7.27829 16.2279 7.029 16.228H5.5C4.83696 16.228 4.20107 15.9646 3.73223 15.4957C3.26339 15.0269 3 14.391 3 13.728V10.272C3 9.60894 3.26339 8.97306 3.73223 8.50422C4.20107 8.03537 4.83696 7.77198 5.5 7.77198H7.029C7.27829 7.77209 7.52367 7.71006 7.74297 7.59151C7.96226 7.47295 8.14856 7.30162 8.285 7.09298C9.28553 5.56633 10.7226 4.37634 12.409 3.67798V20.322Z"
      fill="white"
    />
  </svg>
);

const MutedSVG = () => (
  <svg
    className="muted-svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.652 12C18.6498 11.0404 18.3307 10.1084 17.7442 9.34887C17.1578 8.58935 16.3368 8.04484 15.409 7.79998V1.67998C15.409 1.46007 15.3606 1.24286 15.2672 1.04373C15.1739 0.844607 15.0379 0.668436 14.869 0.527699C14.7 0.386963 14.5021 0.285102 14.2894 0.229333C14.0767 0.173564 13.8543 0.165251 13.638 0.204981C10.6941 0.756901 8.06116 2.38496 6.252 4.77198H5.5C4.0418 4.77357 2.64377 5.35354 1.61267 6.38465C0.581561 7.41576 0.00158817 8.81378 0 10.272L0 13.728C0.00158817 15.1862 0.581561 16.5842 1.61267 17.6153C2.64377 18.6464 4.0418 19.2264 5.5 19.228H6.254C8.06181 21.6165 10.6941 23.2463 13.638 23.8C13.7274 23.8167 13.8181 23.8251 13.909 23.825C14.3068 23.825 14.6884 23.6669 14.9697 23.3856C15.251 23.1043 15.409 22.7228 15.409 22.325V16.2C16.3368 15.9551 17.1578 15.4106 17.7442 14.6511C18.3307 13.8916 18.6498 12.9596 18.652 12ZM12.409 20.322C10.7226 19.6236 9.28553 18.4336 8.285 16.907C8.14856 16.6983 7.96226 16.527 7.74297 16.4085C7.52367 16.2899 7.27829 16.2279 7.029 16.228H5.5C4.83696 16.228 4.20107 15.9646 3.73223 15.4957C3.26339 15.0269 3 14.391 3 13.728V10.272C3 9.60894 3.26339 8.97306 3.73223 8.50422C4.20107 8.03537 4.83696 7.77198 5.5 7.77198H7.029C7.27829 7.77209 7.52367 7.71006 7.74297 7.59151C7.96226 7.47295 8.14856 7.30162 8.285 7.09298C9.28553 5.56633 10.7226 4.37634 12.409 3.67798V20.322Z"
      fill="white"
    />
  </svg>
);

const NavItem = ({ hide = false, onClick }) => (
  <button className={`section-masters-nav__item${hide ? ' hide' : ''}`} onClick={onClick}>
    <svg width="31" height="55" viewBox="0 0 31 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.69446 54.6075L0.991669 48.8925L22.368 27.5L0.991669 6.10746L6.71063 0.392548L28.0708 21.7851C29.5862 23.3009 30.4375 25.3566 30.4375 27.5C30.4375 29.6434 29.5862 31.6991 28.0708 33.2149L6.69446 54.6075Z"
        fill="black"
      />
    </svg>
  </button>
);

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);
  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);
  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
}

export default MastersSection;
