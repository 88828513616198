import React from 'react';
import './index.scss';

const PlatformSection = ({ id }) => {
  return (
    <section id={id} className="section section-platform">
      <img
        className="section-platform-hero"
        src={require('../../assets/platform-hero.png')}
        alt="Платформа"
      />
      <div className="section-platform-wrap">
        <h1>Платформа</h1>
        <h4>
          В дополнение к всему вам будет выдан доступ к платформе для отслеживания расписания и
          возможность покупать уроки
        </h4>
      </div>
    </section>
  );
};

export default PlatformSection;
