import React from 'react';

const Telegram = () => {
  return (
    <div className="social social-telegram">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 24C48 37.2547 37.2547 48 24 48C10.7452 48 0 37.2547 0 24C0 10.7452 10.7452 0 24 0C37.2547 0 48 10.7452 48 24ZM24.8599 17.7179C22.5257 18.6888 17.8602 20.6983 10.8638 23.7466C9.72763 24.1985 9.13248 24.6406 9.07831 25.0726C8.98675 25.8031 9.90142 26.0906 11.1469 26.4823C11.3164 26.5356 11.4919 26.5908 11.6718 26.6491C12.8972 27.0475 14.5456 27.5136 15.4026 27.5321C16.1799 27.5489 17.0474 27.2285 18.0053 26.5706C24.5424 22.158 27.9168 19.9277 28.1285 19.8794C28.278 19.8456 28.4851 19.8029 28.6255 19.9277C28.7659 20.0525 28.752 20.2886 28.7371 20.352C28.6466 20.7384 25.0562 24.0763 23.1982 25.8036C22.619 26.3422 22.2082 26.724 22.1242 26.8114C21.936 27.0067 21.7442 27.1915 21.5599 27.3691C20.4216 28.4666 19.5677 29.2896 21.6072 30.6336C22.5874 31.2794 23.3714 31.8134 24.1538 32.3462C25.0082 32.9282 25.8605 33.5086 26.9633 34.2314C27.2441 34.4155 27.5124 34.6068 27.7738 34.793C28.7681 35.502 29.6614 36.139 30.7651 36.0372C31.4064 35.9784 32.0688 35.3753 32.4053 33.5767C33.2004 29.3263 34.7633 20.1168 35.1245 16.3219C35.1562 15.9895 35.1163 15.564 35.0844 15.3772C35.0522 15.1904 34.9855 14.9242 34.7426 14.7272C34.4551 14.4939 34.0114 14.4447 33.8126 14.448C32.91 14.4641 31.525 14.9456 24.8599 17.7179Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default Telegram;
