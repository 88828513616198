import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { links } from '../../store';
import Instagram from '../Socials/Instagram';
import Telegram from '../Socials/Telegram';
import Whatsapp from '../Socials/Whatsapp';
import './index.scss';

const pages = [
  { name: 'Главная', slug: 'главная' },
  { name: 'О нас', slug: 'о-нас' },
  { name: 'Система обучения', slug: 'система-обучения' },
  { name: 'Преподаватели', slug: 'преподаватели' },
];

const Header = ({ openShedule }) => {
  const [isDark, setIsDark] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const darkHeaderHandle = (e) => {
      const y = window.scrollY;
      if (y > window.innerHeight - 50) setIsDark(true);
      else setIsDark(false);
    };

    darkHeaderHandle();

    window.addEventListener('scroll', darkHeaderHandle);
    return () => window.removeEventListener('scroll', darkHeaderHandle);
  }, []);

  return (
    <header className={`header${open ? ' header--open' : ''}${isDark ? ' header--dark' : ''}`}>
      <div className="header-mobile">
        <div className="header-mobile-wrap">
          {pages.map((page) => (
            <Link
              key={page.slug}
              href={`#${page.slug}`}
              to={page.slug}
              spy
              smooth
              activeClass="active"
              onClick={() => setOpen(false)}
              className={`header-menu__item header-link`}>
              {page.name}
            </Link>
          ))}
          <div className="header-socials">
            <a target="_blank" rel="noreferrer" href={links.instagram}>
              <Instagram />
            </a>
            <a target="_blank" rel="noreferrer" href={links.telegram}>
              <Telegram />
            </a>
            <a target="_blank" rel="noreferrer" href={links.whatsapp}>
              <Whatsapp />
            </a>
          </div>
        </div>
      </div>
      <button onClick={() => setOpen((prev) => !prev)} className="header-open">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className="header-socials">
        <a target="_blank" rel="noreferrer" href={links.instagram}>
          <Instagram />
        </a>
        <a target="_blank" rel="noreferrer" href={links.telegram}>
          <Telegram />
        </a>
        <a target="_blank" rel="noreferrer" href={links.whatsapp}>
          <Whatsapp />
        </a>
      </div>
      <div className="header-menu">
        {pages.map((page) => (
          <Link
            key={page.slug}
            href={`#${page.slug}`}
            to={page.slug}
            spy
            smooth
            activeClass="active"
            className={`header-menu__item header-link`}>
            {page.name}
          </Link>
        ))}
      </div>
      <button onClick={openShedule} className="header-shedule">
        <span className="header-link">Расписание</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.414 10.586L13.121 6.29297L11.707 7.70697L15 11H5V13H15L11.707 16.293L13.121 17.707L17.414 13.414C17.7889 13.0389 17.9996 12.5303 17.9996 12C17.9996 11.4696 17.7889 10.961 17.414 10.586Z"
            fill="#333333"
          />
        </svg>
      </button>
    </header>
  );
};

export default Header;
