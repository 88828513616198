import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './index.scss';

const initCalendar = (date = moment().format('YYYY-MM')) => {
  const startWeek = moment(date).startOf('month').week();
  const endDay = moment(date).endOf('month').date();

  let calendar = [];

  let week = startWeek;
  let lastDay = moment(date);
  while (lastDay.month() === moment(date).month()) {
    if (lastDay.date() === endDay) break;
    calendar.push(
      ...Array(7)
        .fill(0)
        // eslint-disable-next-line no-loop-func
        .map((n, i) =>
          moment(date)
            .week(week)
            .startOf('week')
            .clone()
            .add(n + i, 'day')
        )
    );

    week = moment(date).week(week).add(1, 'week').week();
    lastDay = calendar[calendar.length - 1];
  }

  return calendar;
};

const Shedule = ({ open, onClose }) => {
  const [calendar, setCalendar] = useState(initCalendar);
  const [date, setDate] = useState(moment().format('YYYY-MM'));
  const [shedule, setShedule] = useState({});

  const isMobile = window.innerWidth <= 1000;

  const getShedule = async () => {
    const res = await fetch('https://belins.kz/api/lessons/shedule/all');
    const data = await res.json();
    if (res.ok) {
      setShedule(data);
    }
  };
  useEffect(() => {
    getShedule();
  }, []);

  const changeDate = (type) => {
    let newDate = '';
    if (type === 'prev') {
      newDate = moment(date).subtract(1, 'month').format('YYYY-MM');
    }
    if (type === 'next') {
      newDate = moment(date).add(1, 'month').format('YYYY-MM');
    }
    setCalendar(initCalendar(newDate));
    setDate(newDate);
  };

  const weekdays = isMobile
    ? ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    : ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

  return (
    <div className={`shedule${open ? ' shedule--open' : ''}`}>
      <div className="shedule-title">
        <h1>Расписание</h1>
      </div>
      <div className="shedule-month">
        <button onClick={() => changeDate('prev')}>
          <SmallChevronIcon />
        </button>
        <h3>{moment(date).format('MMMM YYYY')}</h3>
        <button onClick={() => changeDate('next')}>
          <SmallChevronIcon />
        </button>
      </div>
      <button onClick={onClose} className="shedule-close">
        <h5>закрыть</h5>
      </button>
      <div className="shedule-wrap">
        {weekdays.map((x) => (
          <span className="shedule-wrap-weekday" key={x}>
            {x}
          </span>
        ))}
        {calendar.map((day) => (
          <div
            key={day}
            className={`shedule-wrap__item${
              day.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
                ? ' shedule-wrap__item--today'
                : ''
            }`}>
            <span className="shedule-wrap__item-date">{day.format('DD')}</span>

            <div className="shedule-wrap__item-lessons">
              {shedule[day.format('YYYY-MM-DD')]?.lessons?.map((lesson) => (
                <div key={lesson.id} className="shedule-wrap__item-lessons__item">
                  {lesson.sheduleTimeStart.slice(0, 5)}
                  <i>{lesson.dance.label}</i>
                  {lesson.master && <span> - {lesson.master.name}</span>}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SmallChevronIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" width="24" height="24">
    <path d="M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" />
  </svg>
);

export default Shedule;
