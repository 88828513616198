import React, { useState } from 'react';
import PriceLink from '../../components/PriceLink/PriceLink';
import './index.scss';

import blueBack from '../../assets/blue_scratches.png';
import platformPack from '../../assets/platform-pack.png';
import { links } from '../../store';

const types = [
  { label: 'BEG', slug: 'beg' },
  { label: 'PRO', slug: 'pro' },
];

const StudySection = ({ id }) => {
  const [activeType, setType] = useState('beg');

  return (
    <section id={id} className="section section-study">
      <h2>Система обучения</h2>
      <div className="section-study-select">
        {types.map((type) => (
          <button
            key={type.slug}
            onClick={() => setType(type.slug)}
            className={`section-study-select__item${activeType === type.slug ? ' active' : ''}`}>
            {type.label}
          </button>
        ))}
      </div>
      {activeType === 'beg' && (
        <div className="section-study-case">
          <div className="section-study-case-block">
            <h3 className="section-study-name">CHOREO Начинающие</h3>
            <div className="section-study-equation">
              <div className="section-study-block">
                <h1>Групповая система</h1>
                <p>
                  Обучение с блоками в 2 недели. Вы выбираете группу с постоянным расписанием, где
                  вы можете заниматься у нескольких преподавателей.
                </p>
                <p>
                  <span>Одна группа</span>, но несколько хореографов.
                </p>
                <p>
                  Хореографы работают с группой по очереди периодами в 2 недели, с уклоном на
                  определенную танцевальную тему.
                </p>
              </div>
              <svg
                className="section-study-equation-plus"
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.6 52V31.1H2.83122e-07V21H20.6V0.0999947H31.4V21H52V31.1H31.4V52H20.6Z"
                  fill="white"
                />
              </svg>
              <div className="section-study-block">
                <h1>Разные учителя</h1>
                <p>
                  У ученика есть 2+ преподавателя и наставника, которые могут не только{' '}
                  <span>научить его танцевать</span> различные уличные стили (Hiphop, House Dance,
                  Afro, Popping и тд)
                </p>
                <p>
                  Но и помочь ему найти свой танец, свою <span>«изюминку»</span>
                </p>
                <p>
                  Плавно воспитывая его <span>с 0 до профессионального танцора</span>
                </p>
                <p>
                  <span>Преподаватели:</span> Женя, Ринат, Лязка, Данара
                </p>
              </div>
              <svg
                className="section-study-equation-equal"
                width="52"
                height="33"
                viewBox="0 0 52 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.83122e-07 11.2V-1.66893e-06H52V11.2H2.83122e-07ZM2.83122e-07 32.1V20.9H52V32.1H2.83122e-07Z"
                  fill="white"
                />
              </svg>
              <div className="section-study-equation-result">
                <h1>
                  <span>2</span> <span>+</span>
                </h1>
                <div>
                  <h4>преподавателя</h4>
                  <h4>ресурса информации</h4>
                  <h4>досконально пройденных темы</h4>
                </div>
              </div>
            </div>
            <div className="section-study-price">
              <PriceLink />
              <div className="section-study-price-wrap">
                <div className="section-study-price-block">
                  <h3>ДЛЯ ГРУППЫ BEG1</h3>
                  <h6>Понедельник, среда, пятница (17:00 - 18:20)</h6>
                  <ul>
                    <li>24 000 ₸ - блок в 6 занятий</li>
                    <li>36 000 ₸ - блок в 12 занятий</li>
                  </ul>
                </div>
                <div className="section-study-price-block">
                  <h3>ДЛЯ ГРУППЫ BEG2</h3>
                  <h6>Вторник, четверг (18:30 - 19:50)</h6>
                  <ul>
                    <li>18 000 ₸ - блок в 4 занятия</li>
                    <li>32 000 ₸ - блок в 8 занятий</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-study-case-block">
            <h3 className="section-study-name">HIGH HEELS Начинающие</h3>
            <div className="section-study-equation" style={{ justifyContent: 'center' }}>
              <div className="section-study-block">
                <h1>Групповая система</h1>
                <p>
                  <span>Постоянная группа</span> которую ведет наш преподаватель Милана
                </p>
                <p>
                  ✦ Раскрытие женской энергии
                  <br />
                  ✦ Правильная и безопасная ходьба на каблуках
                  <br />
                  ✦ Пластичность в движении
                  <br />
                  ✦ Работа над самооценкой
                  <br />
                  ✦ Техничный танец
                  <br />
                </p>
              </div>
              <div className="section-study-price-block">
                <h3>ГРАФИК ЗАНЯТИЙ</h3>
                <h6>Понедельник, среда, пятница (20:00 - 21:20)</h6>
                <ul>
                  <li>30 000 ₸ - 12 занятий (1 месяц)</li>
                  <li>50 000 ₸ - 24 занятий (2 месяца)</li>
                  <li>60 000 ₸ - 36 занятий (3 месяца)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeType === 'pro' && (
        <div className="section-study-case">
          <h3 className="section-study-name">Продолжающие</h3>
          <div className="section-study-web">
            <WebSVG />
            <div className="section-study-block">
              <h1>Классовая система</h1>
              <p>
                Для <span>продолжающих</span> танцоров мы подготовили классовую систему по которой
                наши ученики могут заниматься у всех наших (и не только) преподавателей.
              </p>
              <p>
                Эта система позволяет ученикам избежать полного копирования преподавателя и
                открывает <span>новое пространство</span> для поиска себя и своей уникальности в
                танце.
              </p>
            </div>
            <div className="section-study-price-block">
              <h3>КЛАССОВАЯ СИСТЕМА</h3>
              <ul>
                <li>5 000 ₸ - 1 класс</li>
                <li>16 000 ₸ - 4 класса</li>
                <li>20 000 ₸ - 6 классов</li>
                <li>24 000 ₸ - 8 классов</li>
                <li className="super">50 000 ₸ - безлимит</li>
              </ul>
            </div>
            <div className="section-study-block">
              <h1>КАК ЗАПИСАТЬСЯ?</h1>
              <ul>
                <li>
                  Зайдите на <span>платформу</span>
                </li>
                <li>Зарегистрируйтесь</li>
                <li>
                  Пополните баланс через Kaspi ссылку <br />
                  <i>
                    (или свяжитесь с админом чтобы пополнить баланс наличкой или картой другого
                    банка )
                  </i>
                </li>
                <li>Регистрируйтесь на классы со своего баланса</li>
              </ul>
              <img src={platformPack} alt="" />
              <a
                target="_blank"
                rel="noreferrer"
                href={links.platform}
                style={{ backgroundImage: `url(${blueBack})` }}>
                <span>Вперед!</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const WebSVG = () => (
  <svg
    width="1920"
    height="458"
    viewBox="0 0 1920 458"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1161 277C1166.5 154 1331.2 10.9 1500 2.49999C1711 -8.00001 1729.66 144 1839 144C1933 144 1973 95.5 2002 8.49998M1161 277C1239.5 352 1496.4 338 1618 216C1739.6 94 1878.67 189.833 1933 253M1161 277C1169 304 1208 442.1 1298 426.5C1410.5 407 1543.5 289.5 1656 362C1768.5 434.5 1887 428.5 1933 404.5M1161 277C1208.5 248 1367.3 331.3 1488.5 426.5C1640 545.5 1862 270.5 1967.5 284M1161 277C1184 220.5 1332.8 118.9 1432 104.5C1556 86.5 1590.5 212.5 1672 224C1801 242.202 1885.5 184.167 1951.5 158.5M1161 277C1161 277 948 277 724.5 291C607.729 298.314 609.7 216.6 502.5 253C368.5 298.5 203 289 138 235C86 191.8 -40.6667 143.667 -97.5 125"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export default StudySection;
