export const links = {
  platform: 'https://app.belins.kz/',
  telegram: 'https://t.me/belinsvideo',
  instagram: 'https://instagram.com/belinsstudio?igshid=YmMyMTA2M2Y=',
  whatsapp: 'https://wa.me/7028003555',
  whatsappToDances: 'https://wa.me/7028003555?text=Привет. Я хочу записаться к вам на танцы.',
  whatsappOffer: 'https://wa.me/7028003555?text=Здравствуйте, пишу с вашего сайта)\n Запишите, пожалуйста, меня на пробное занятие',
  whatsappToMaster: (master) => `https://wa.me/7028003555?text=Привет. Я хочу записаться к мастеру ${master} на танцы.`,
}

export const masters = [
  {
    name: 'Женя',
    slug: 'zhenya',
    startTime: 0,
    video: require('./assets/masters/zhenya.mp4'),
    hero: require('./assets/masters/zhenya.jpg'),
    dances: 'CHOREO и HIPHOP',
    places: [
      ['ISDS19 BEST SHOW MEDIUM', 'Dvizh Party'],
      [
        'ISDS online THE BEST CHOREOGRAPHER',
        'ISDS21 THE BEST PERFORMANCE ADULTS',
        'Dizzy Best Show',
        'Face 2 Face BEST CHOREO CREW 16+',
      ],
      [
        'Face 2 Face BEST CHOREO SOLO 16+',
        'WOD Turkey Upper Division Teams',
        'ISDS21 SHOW CREW PRO',
        'ISDS21 SHOW CREW PRO',
        'Face 2 Face BEST CHOREO CREW 16+',
        'ISDS online THE BEST PERFORMANCE',
      ],
    ],
    description: (
      <>
        <p>
          Сольный шоукейс на World Of Dance Turkey (2022) <br />
          Участница команды <span>O.U.CH!</span> и <span>THECLUB</span>
        </p>
        <p>
          <span>Давала классы</span> в таких городах как Алматы, Астана, Бишкек, Караганда,
          Усть-Каменогорск, Актобе, Атырау, Шымкент.
        </p>
        <p>
          Тренировалась на таких <span>танцевальных лагерях</span> как CAMPFABRIKA (2021), HDI
          Easter Dance Camp (2022), CAMPFABRIKA (2022), VOLGA CAMP (2023)
        </p>
        <p>
          <span>Заслужила стипендию</span> в HDI CAMP на CAMPFABRIKA21
        </p>
        <p>
          Проходила мастер-классы у более <span>90</span> хореографов по миру таких как Lyle Beniga,
          Keone&Mari Madrid, Anthony Lee, Melvin Timtim, Bailey Sok, Kiel Tutin, Natalie Bebko, Toby
          Deedaran, Kenzo Alvarez, Jinwoo Yoon, Koosung Jung и тд
        </p>
      </>
    ),
  },
  {
    name: 'Милана',
    slug: 'milka',
    startTime: 3,
    video: require('./assets/masters/milka.mp4'),
    hero: require('./assets/masters/milka.jpg'),
    dances: 'CHOREO и HIGH HEELS',
    places: [],
    description: (
      <>
        <p>
          Опыт в танцах - <span>7 лет</span>
        </p>
        <p>
          Опыт в преподавании - <span>7 лет</span>
        </p>
        <p>
          Прокачивалась у таких <span>мировых хореографов</span> как
          <br />
          Fraules, Olya Boyko, Дена Платонова, Ксения Пупынина, Elaterina Epaneshnikova, Анастасия
          Матюшина, Саша Ниталиева, Виталий Клименко, Лилия Едиханова, Amandy Fernandes,
        </p>
        <p>
          Опыт в таких <span>танцевальных стилях</span> как
          <br />
          Hip-hop, Vogue, Dancehall , Contemporary , Bachata, Juzz funk , Reggaeton , High heels,
          Twerk, Afro, Expremental,
        </p>
      </>
    ),
  },
  {
    name: 'Вероника',
    slug: 'vika',
    startTime: 2,
    video: require('./assets/masters/vika.mp4'),
    hero: require('./assets/masters/vika.jpg'),
    dances: 'CHOREO и FRAMEUP STRIP',
    places: [
      [
        'Best Solo Performance adults на ISDS Lite 2021',
        'ExoDance Fest Almaty в составе UniQueens',
        'Frame Up Strip 1x1 на Frame Up Strip BATTLE 2021',
      ],
      [
        'High Heels 1x1 на Frame Up Strip BATTLE 2021',
        'Strip & Heels choreo solo на #нетойская вечеринка 2021',
        'Best Solo High Heels на Face to Face 2021',
        'Best Show Professionals в составе HAREM',
      ],
      ['Frame Up Competition 2022', 'Performance PRO на Almaty Dance Fest 2023'],
    ],
    description: (
      <>
        <p>
          Сертифицированный представитель <span>Frame Up Strip</span>
        </p>
        <p>
          Посещала <span>Frame Up Camp 2022</span> и <span>Frame Up Convention 2023</span>
        </p>
        <p>
          Посещала классы <span>Amandy Fernandez</span> в Алматы
        </p>
        <p>
          Также посетила большое множество местных и привозных классов хореографов с{' '}
          <span>России, Кореи, Европы и США</span>
        </p>
      </>
    ),
  },
  {
    name: 'Ринат',
    slug: 'rinat',
    startTime: 2.2,
    video: require('./assets/masters/rinat.mp4'),
    hero: require('./assets/masters/rinat.jpg'),
    dances: 'CHOREO и HIPHOP',
    places: [
      [
        'So What The Funk 2022 hip hop pro 4x4',
        'So What The Funk 2020 hip hop pro 2x2',
        'Almaty Urban Fest 2021 hip hop pro 1x1',
        'Dvizh Battle 2022 hip hop pro 1x1',
      ],
      ['Almaty Urban Fest 2022 hip hop pro 1x1', 'Gorilla Style Wars 2018'],
      [
        'The Vibe Kyiv 2021 hip-hop pro',
        'Gorilla Style Wars 2022 hip hop 1x1',
        'Fusion Concept Kazakhstan 2019 2x2',
      ],
    ],
    description: (
      <>
        <p>
          Music video <span>Feduk</span> - Танцы на кухне
          <br />
          <span>Dimash Kudaibergenov</span> Concert in Almaty (2022) 
          <br />
          <span>Roza Rymbaeva</span> Concert in Almaty (12.2022)
          <br />
          Music video <span>Ninenty One</span> - SEÑORITA (2020)
          <br />
          Alikhan Show Performance (2022-2023)
          <br />
          And more.
        </p>
        <p>
          Прокачивался у таких мировых танцоров как Franky dee, Majid, Kanon, Kuty, Rochka, Michelle
          beatz, Maksim Bon, Tadj, Zolsky, Batalla, Stylez c, Kefton, Swipe Sow, Ramon
        </p>
      </>
    ),
  },
  {
    name: 'Данара',
    slug: 'dana',
    startTime: 2.1,
    video: require('./assets/masters/dana.mp4'),
    hero: require('./assets/masters/dana.jpg'),
    dances: 'CHOREO',
    places: [
      [],
      [
        'Face to face 2022 - Show crew professionals',
        'Isds 2019 - The best show',
        'Silk way dance fest 2017 - The best show',
      ],
      [
        'World of Dance Turkiye- 2022- Upper Team Division',
        'Isds 2021 - Show crew professionals',
        'Face to Face 2017 - The best show',
        'Face to face 2022 - Best crew',
      ],
    ],
    description: (
      <>
        <p>
          Профессиональный хореограф-постановщик различных шоу с 2019 года с призовыми местами.{' '}
        </p>
        <p>
          Опыт в танцах - <span>15 лет</span> <br />
          (8 лет занималась профессионально бальными танцами и 6 лет занимаюсь современными уличными
          направлениями)
        </p>
        <p>
          Стаж преподавания - <span>3,5 года</span>
        </p>
        <p>Участница The Club, Fast Flow, The Home, Miracle Motion</p>
        <p>
          Прошла <span>100+ мастер классов</span> у ТОПовых мировых хореографов, также Казахстанских
          и СНГ.
        </p>
        <p>
          Прокачивалась на танцевальном лагере в Турции <span>CAMPFABRIKA22</span>
        </p>
      </>
    ),
  },
  {
    name: 'Лязка',
    slug: 'lyazka',
    startTime: 2,
    video: require('./assets/masters/lyazka.mp4'),
    hero: require('./assets/masters/lyazka.jpg'),
    dances: 'CHOREO и HIGH HEELS',
    places: [
      [
        "Beginner's Show в составе «Fast Flow» (@timetwoshock)",
        'Street Show в составе «Fast Flow» (@tantsuyvseydushoy)',
        "Beginner's Show в составе «Fast Flow» (@face_to_face_)",
        'Narxoz Choice Dance в составе «Fast Flow» (@growup_organization)',
      ],
      ['Show Pro в составе «The Club» (@face_to_face_)'],
      [
        'Show Pro в составе «The Club» (@face_to_face_)',
        'Show Pro в составе «The Club» (@isds.kz)',
        'ISDS2021 Best show pro в составе «The Club»',
        'WOD Turkey Upper Division Teams в составе «The Club»',
      ],
    ],
    description: (
      <>
        <p>
          Опыт в танцах - <span>17 лет</span>
          <br />
          10 лет в бальных танцах, 7 лет в современных.
        </p>
        <p>
          Стаж преподавания - <span>6 лет</span>
        </p>
        <p>
          В прошлом финалистка чемпионата Казахстана по бально-спортивным танцам, так же выигрывала
          множество международных соревнований
        </p>
        <p>
          <span>Мастер классы:</span> <br /> Yanis Marshall, Brian Friedman, Nicole Kirkland, Cisco
          Rueleas, Kira Harper, Aliya Janell, Kenzo Alvares, Janelle Ginestra, Anthony Lee, Zacc
          Milne, Kirsten Dodgen, Jade Chynoweth, Mamson, Joseph Go, Wizzard, Мадина Бейсекеева,
          Katrin Wow, Nina Bonchinche, Claudio Black Eagle, Андрей Бойко, Ilya Rysin, Fraules, Olya
          Boyko, Юлия Мурашко
        </p>
      </>
    ),
  },
];
