import React from 'react';

const Whatsapp = () => {
  return (
    <div className="social social-whatsapp">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.58144 23.9999C5.58144 13.6275 14.0776 5.21903 24.5581 5.21903C35.0387 5.21903 43.5349 13.6275 43.5349 23.9999C43.5349 34.3723 35.0387 42.7809 24.5581 42.7809C20.8249 42.7809 17.3498 41.7163 14.4178 39.8778C13.8722 39.5357 13.2035 39.4443 12.5846 39.627L6.1567 41.5256L8.57689 35.921C8.86295 35.2585 8.80474 34.4998 8.42092 33.8878C6.6211 31.0174 5.58144 27.6322 5.58144 23.9999ZM24.5581 0.799989C11.6116 0.799988 1.11633 11.187 1.11633 23.9999C1.11633 28.0613 2.17264 31.8842 4.02899 35.2082L0.179656 44.122C-0.160635 44.9102 -0.00979916 45.8218 0.56667 46.4612C1.14314 47.1009 2.04127 47.3528 2.87105 47.1075L12.9163 44.1408C16.3478 46.0874 20.3245 47.1999 24.5581 47.1999C37.5047 47.1999 48 36.8129 48 23.9999C48 11.187 37.5047 0.799989 24.5581 0.799989ZM29.6763 28.822L26.7423 30.8673C25.3681 30.0927 23.8489 29.0113 22.3249 27.5031C20.7407 25.9353 19.5662 24.3166 18.7002 22.8318L20.5648 21.2654C21.3649 20.5933 21.5839 19.4601 21.091 18.543L18.7152 14.124C18.3953 13.529 17.8184 13.111 17.1486 12.9889C16.4788 12.8668 15.7893 13.054 15.2762 13.4972L14.5718 14.1056C12.8779 15.5687 11.8761 17.973 12.7064 20.4075C13.5672 22.9312 15.4043 26.9032 19.1677 30.6278C23.2166 34.635 27.3294 36.2131 29.7345 36.826C31.6724 37.3196 33.5062 36.6578 34.7984 35.6158L36.119 34.5508C36.6838 34.0955 36.9886 33.3957 36.9354 32.6765C36.8821 31.9573 36.4775 31.309 35.8515 30.9398L32.1046 28.7303C31.3478 28.2842 30.3967 28.32 29.6763 28.822Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default Whatsapp;
