import React, { useState } from 'react';
import styles from './index.module.scss';

import hero from '../../assets/form-hero.png';

const Form = ({ id }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const [form, setForm] = useState({
    name: '',
    phone: '',
  });

  const notify = (msg) => {
    setError(msg);
    setTimeout(() => {
      setError(false);
    }, 1000);
  };

  const onSubmit = async () => {
    const { name, phone } = form;

    if (!name || !phone) {
      notify('Заполните все поля');
      return;
    }

    setLoading(true);
    const response = await fetch(`/api/form?name=${name}&phone=${phone}`);
    const result = await response.json();

    if (!result) {
      notify('Ошибка при отправке');
    }

    setSended(true);
    setLoading(false);
  };

  return (
    <section id={id} className={`section section-form ${styles.wrap}`}>
      <div className={styles.left}>
        <h1 className={styles.title}>Оставьте заявку</h1>
        <div className={styles.form}>
          <input
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            required
            placeholder="Ваше имя"
            name="name"
          />
          <input
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: e.target.value })}
            required
            placeholder="Ваш телефон"
            name="phone"
          />
        </div>
        <Decor />
        <button
          disabled={loading}
          onClick={onSubmit}
          className={styles.button + (sended ? ' ' + styles.buttonSended : '')}>
          {sended ? 'Отправлено 🚀' : 'Отправить'}
        </button>
        <h6
          className={styles.error}
          style={{ transform: error ? 'translateY(0%)' : 'translateY(-200%)' }}>
          {error}
        </h6>
      </div>
      <div className={styles.right}>
        <img className={styles.hero} src={hero} alt="Оставьте заявку" />
      </div>
    </section>
  );
};

const Decor = () => (
  <svg
    className={styles.decor}
    width="275"
    height="328"
    viewBox="0 0 275 328"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      d="M149.251 270.853C143.734 279.374 131.266 279.374 125.749 270.853L43.6736 144.11C37.6416 134.795 44.3276 122.5 55.4249 122.5L219.575 122.5C230.672 122.5 237.358 134.795 231.326 144.11L149.251 270.853Z"
      fill="white"
    />
    <path
      opacity="0.04"
      d="M149.512 174.973C143.907 182.113 133.093 182.113 127.488 174.973L44.9938 69.8951C37.7816 60.7085 44.3262 47.25 56.0057 47.25L220.994 47.25C232.674 47.25 239.218 60.7085 232.006 69.8952L149.512 174.973Z"
      fill="white"
    />
    <path
      opacity="0.05"
      d="M148.444 314.284C142.839 321.308 132.161 321.308 126.556 314.283L36.5576 201.481C29.2417 192.312 35.7707 178.75 47.5013 178.75L227.499 178.75C239.229 178.75 245.758 192.312 238.442 201.481L148.444 314.284Z"
      fill="white"
    />
  </svg>
);

export default Form;
