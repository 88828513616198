import React from 'react';

const Instagram = () => {
  return (
    <div className="social social-instagram">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34.7535 0H13.246C5.94215 0 0 5.94243 0 13.2463V34.7537C0 42.0578 5.94215 48 13.246 48H34.7535C42.0578 48 48 42.0576 48 34.7537V13.2463C48.0003 5.94243 42.0578 0 34.7535 0ZM43.7415 34.7537C43.7415 39.7095 39.7095 43.7412 34.7537 43.7412H13.246C8.29045 43.7415 4.25879 39.7095 4.25879 34.7537V13.2463C4.25879 8.29073 8.29045 4.25879 13.246 4.25879H34.7535C39.7093 4.25879 43.7412 8.29073 43.7412 13.2463V34.7537H43.7415Z"
          fill="#202020"
        />
        <path
          d="M24.0007 11.6323C17.1806 11.6323 11.6323 17.1807 11.6323 24.0007C11.6323 30.8205 17.1806 36.3685 24.0007 36.3685C30.8207 36.3685 36.369 30.8205 36.369 24.0007C36.369 17.1807 30.8207 11.6323 24.0007 11.6323ZM24.0007 32.1095C19.5292 32.1095 15.8911 28.4719 15.8911 24.0004C15.8911 19.5287 19.5289 15.8908 24.0007 15.8908C28.4724 15.8908 32.1102 19.5287 32.1102 24.0004C32.1102 28.4719 28.4721 32.1095 24.0007 32.1095Z"
          fill="#202020"
        />
        <path
          d="M36.8878 8.02148C36.0672 8.02148 35.2612 8.35367 34.6817 8.93571C34.0994 9.5149 33.7646 10.3212 33.7646 11.1446C33.7646 11.9654 34.0997 12.7715 34.6817 13.3535C35.2609 13.9327 36.0672 14.2677 36.8878 14.2677C37.7111 14.2677 38.5146 13.9327 39.0967 13.3535C39.6787 12.7715 40.0109 11.9651 40.0109 11.1446C40.0109 10.3212 39.6787 9.5149 39.0967 8.93571C38.5175 8.35367 37.7111 8.02148 36.8878 8.02148Z"
          fill="#202020"
        />
      </svg>
    </div>
  );
};

export default Instagram;
