import React, { useRef } from 'react';
import './index.scss';
import SpecialOffer from '../../components/SpecialOffer/SpecialOffer';

const MainPage = ({ id }) => {
  const titleRef = useRef();

  return (
    <section id={id} className="section section-main">
      <SpecialOffer titleRef={titleRef} />
      <div className="section-main-fixed">
        <div ref={titleRef} className="section-main-title">
          <img src={require('../../assets/logo-color.png')} alt="Belins Logo" className="logo" />
          <h2>Танцевальная студия</h2>
        </div>
      </div>

      <div className="section-main-hero">
        <a href='#' className="section-main-hero_link">

        </a>
        <picture>
          <source srcSet={require('../../assets/mainhero-mobile.png')} media="(max-width: 800px)" />
          <img
            src={require('../../assets/mainhero.png')}
            alt="Belins Team"
          />
        </picture>
      </div>
    </section>
  );
};

export default MainPage;
