import React from 'react';
import './index.scss';

const AboutSection = ({ id }) => {
  return (
    <section id={id} className="section section-about">
      <div className="section-about-block">
        <h2 className="section-about-pretitle">
          BELINS это об обучении из любви, открытом комьюнити и конечно же танце
        </h2>
        <div className="section-about-title">
          <h1>О нас</h1>
          <p>
            <b>Наша миссия:</b> <br />
            выращивать новое танцевальное поколение на системе, приоретизирующей качественное
            преподавание и любовь к танцу.
          </p>
        </div>
      </div>
      <div className="section-about-block">
        <p>
          Мы избавим вас от точки застоя в танцевальном развитии и предоставим нужные информацию,
          пространство, единомышленников для выхода на новый уровень.
        </p>
        <div className="section-about-segments">
            <div className="section-about-segments__item">
                <img src={require('../../assets/segment1.png')} alt="" />
            </div>
            <div className="section-about-segments__item">
                <img src={require('../../assets/segment2.png')} alt="" />
            </div>
            <div className="section-about-segments__item">
                <img src={require('../../assets/segment3.png')} alt="" />
            </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
