import React from 'react';
import Instagram from '../Socials/Instagram';
import Whatsapp from '../Socials/Whatsapp';
import './index.scss';
import Telegram from '../Socials/Telegram';
import { links } from '../../store';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-block">
        <h4>
          <a href="tel:7702800355">+7 (702) 800 35-55</a>
        </h4>
        <h4><a href="https://go.2gis.com/ujk56" target='_blank' rel='noreferrer'>Алматы, Торайгырова 21/1</a></h4>
      </div>
      <div className="footer-hero">
        <img src={require('../../assets/logo-color.png')} alt="Belins Studio" />
      </div>
      <div className="footer-socials">
        <a target='_blank' rel='noreferrer' href={links.telegram}>
          <Telegram />
        </a>
        <a target='_blank' rel='noreferrer' href={links.instagram}>
          <Instagram />
        </a>
        <a target='_blank' rel='noreferrer' href={links.whatsapp}>
          <Whatsapp />
        </a>
      </div>
      
      <a href="https://only-dev.kz" target='_blank' rel='noreferrer' className="footer-only"><img src="https://only-dev.kz/link-black.png" alt="Only." /></a>
    </footer>
  );
};

export default Footer;
